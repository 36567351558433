export const Subdomain = async (request: any) => {
  try {
    const s = request.headers?.host?.split('.')

    const subs = [
      {
        sub: 'galapagos',
        image:
          'https://d1k1f4n2h095ym.cloudfront.net/b2b/galapagos/login_galapagos.png',
        logo: 'https://d1k1f4n2h095ym.cloudfront.net/b2b/galapagos/galapagos_logo_blue.svg'
      },
      {
        sub: 'devgalapagos',
        image:
          'https://d1k1f4n2h095ym.cloudfront.net/b2b/galapagos/login_galapagos.png',
        logo: 'https://d1k1f4n2h095ym.cloudfront.net/b2b/galapagos/galapagos_logo_blue.svg'
      },
      {
        sub: 'positivo',
        image:
          'https://d1k1f4n2h095ym.cloudfront.net/b2b/positivo/login_positivo.png',
        logo: 'https://d1k1f4n2h095ym.cloudfront.net/b2b/positivo/logo-positivo-white.svg'
      },
      {
        sub: 'devpositivo',
        image:
          'https://d1k1f4n2h095ym.cloudfront.net/b2b/positivo/login_positivo.png',
        logo: 'https://d1k1f4n2h095ym.cloudfront.net/b2b/positivo/logo-positivo-white.svg'
      }
    ]

    return subs.find((item) => item.sub == s[0]) ?? null
  } catch (error) {
    return null
  }
}

export const getSubdomain = (url: string) => {
  let domain = url
  if (url.includes('://')) {
    domain = url.split('://')[1]
  }
  const subdomain = domain.split('.')[0]
  return subdomain
}
