import axios from 'axios'

const { API_URL, NEXTAUTH_URL } = process.env

export const api = axios.create({
  baseURL: API_URL
  // headers: {
  // 'X-Requested-With': 'XMLHttpRequest',
  // }
  // withCredentials: true,
})

export const nextApi = axios.create({
  baseURL: NEXTAUTH_URL,
  headers: {
    'Content-Type': 'application/json'
  }
})
