import { ImageStyles } from '@/component/ImageStudio/ModalImageStyle'
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useState
} from 'react'

interface ImageGenerateProviderProps {
  children: ReactNode
}

type ImageGenerateContextData = {
  prompt: string
  setPrompt: (s: string) => void
  selectedStyles: ImageStyles[]
  setSelectedStyles: Dispatch<SetStateAction<ImageStyles[]>>
  selectStyleNames: string | undefined
}

const ImageGenerateContext = createContext({} as ImageGenerateContextData)

export function ImageGenerateProvider({
  children
}: ImageGenerateProviderProps) {
  const [prompt, setPrompt] = useState('')
  const [selectedStyles, setSelectedStyles] = useState<ImageStyles[]>([])
  const selectStyleNames = selectedStyles.map((p) => p.name).join()

  return (
    <ImageGenerateContext.Provider
      value={{
        prompt,
        setPrompt,
        selectedStyles,
        selectStyleNames,
        setSelectedStyles
      }}
    >
      {children}
    </ImageGenerateContext.Provider>
  )
}

export const useImageGenerate = () => useContext(ImageGenerateContext)
