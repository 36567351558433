import { extendTheme } from '@chakra-ui/react'
import { Button, Spinner, Stepper, Tabs } from './components'
import { AppColors, colorsDark, colorsLight } from './colors'

const theme = {
  config: {
    initialColorMode: 'light',
    useSystemColorMode: false,
    cssVarPrefix: 'gx'
  },
  components: {
    Button,
    Spinner,
    Stepper,
    Tabs
  },
  colors: {
    blue: {
      200: '#ADD8FF',
      300: '#87CEEB',
      400: '#4682B4',
      500: '#3498DB',
      600: '#2E4053'
    },
    green: {
      rgba100: 'rgba(77, 249, 174, 0.24)',
      rgba200: 'rgba(77, 249, 174, 0.15)',
      rgba300: 'rgba(77, 249, 174, 0.10)',
      200: '#7CF9C2',
      300: '#4DF9AE',
      400: '#47E7A2',
      500: '#3FC7A9',
      600: '#2FAD73'
    },
    grey: {
      rgba300: 'rgba(141, 136, 181, 0.20)',
      25: '#716E84',
      50: '#726D8F',
      100: '#8D88B5',
      200: '#726C97',
      300: '#58537A',
      400: '#403C5D',
      500: '#322F4D',
      600: '#2A2740',
      900: '#222034',
      950: '#1B1929'
    },
    pink: {
      300: '#F86078'
    },
    yellow: {
      rgba100: 'rgba(300, 550, 18, 0.24)',
      300: '#CCC888',
      400: '#BCC046'
    },
    greenLight: {
      400: '#7CF9C2',
      500: '#47E7A2'
    }
  },
  fonts: {
    heading: `'Inter', sans-serif`,
    body: `'Inter', sans-serif`,
    input: `'Inter', sans-serif`
  },
  fontSizes: {
    xs: '0.75rem', //12px
    sm: '0.875rem', //14px
    md: '1rem', //16px
    lg: '1.125rem', //18px
    xl: '1.25rem', //20px
    '2xl': '1.5rem', //24px
    '3xl': '2rem', //32px
    '4xl': '2.5rem',
    '5xl': '3rem',
    '6xl': '3.5rem',
    '7xl': '4rem',
    '8xl': '5rem',
    '9xl': '8rem'
  },
  textStyles: {
    inter: {
      fontFamily: `'Inter', sans-serif`
    },
    poppins: {
      fontFamily: `'Poppins', sans-serif`
    },
    space: {
      fontFamily: `'Space Grotesk', sans-serif`
    },
    sora: {
      fontFamily: `'Sora', sans-serif`
    },
    small: {
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '16px'
    },
    extraSmall: {
      fontWeight: 400,
      fontSize: '10px',
      lineHeight: '24px'
    },
    smallTitle: {
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '24px'
    },
    medium: {
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '21px'
    },
    mediumTitle: {
      fontWeight: 700,
      fontSize: '14px',
      lineHeight: '17px'
    },

    large: {
      fontWeight: 700,
      fontSize: '16px',
      lineHeight: '24px'
    }
  },
  fontWeights: {
    normal: 400,
    semibold: 600,
    bold: 700
  }
}

type GetAppThemeProps = {
  appColorsDark?: AppColors
  appColorsLight?: AppColors
}
export const getAppTheme = ({
  appColorsDark = colorsDark,
  appColorsLight = colorsLight
}: GetAppThemeProps) => {
  return extendTheme({
    ...theme,
    colors: {
      light: appColorsLight,
      dark: appColorsDark,
      ...theme.colors
    }
  })
}
