export const colorsLight = {
  primary: {
    main: '#385CA9',
    onPrimary: '#FFFFFF',
    container: '#D9E2FF',
    onContainer: '#001945',
    mainHover: 'rgba(56, 92, 169, 0.8)',
    rgba20: 'rgba(56, 92, 169, 0.2)',
    rgba60: 'rgba(56, 92, 169, 0.6)'
  },
  secondary: {
    main: '#4B577E',
    onSecondary: '#FFFFFF',
    container: '#DCE2F9',
    onContainer: '#121A2F',
    secondaryHover: 'rgba(75, 87, 126, 0.8)',
    rgba20: 'rgba(75, 87, 126, 0.2)',
    rgba60: 'rgba(75, 87, 126, 0.6)'
  },
  error: {
    main: '#BA1A1A',
    onError: '#FFFFFF',
    container: '##FFDAD6',
    onContainer: '#690005'
  },
  success: {
    main: '#198754',
    onSuccess: '#FFFFFF',
    container: '#d1e7dd',
    onContainer: '#0f5132'
  },
  surface: {
    main: '#FFFFFF',
    dim: '#9F98B3',
    containerLowest: '#F2F0FA',
    containerLow: '#F0EEF6',
    container: '#E8E5F0',
    containerHeight: '#DDDAE7',
    containerHighest: '#D0CDDA',
    onSurface: '#1B1B1F',
    onSurfaceVariant: '#40404F'
  },
  background: '#F8F6FD',
  outline: '#73738C',
  outlineVariant: '#DDDAE7',
  black: '#17161E'
}

export const colorsDark = {
  primary: {
    main: '#385CA9',
    onPrimary: '#FFFFFF',
    container: '#D9E2FF',
    onContainer: '#001945',
    mainHover: 'rgba(56, 92, 169, 0.8)',
    rgba20: 'rgba(56, 92, 169, 0.2)',
    rgba60: 'rgba(56, 92, 169, 0.6)'
  },
  secondary: {
    main: '#4B577E',
    onSecondary: '#FFFFFF',
    container: '#DCE2F9',
    onContainer: '#121A2F',
    secondaryHover: 'rgba(75, 87, 126, 0.8)',
    rgba20: 'rgba(75, 87, 126, 0.2)',
    rgba60: 'rgba(75, 87, 126, 0.6)'
  },
  error: {
    main: '#BA1A1A',
    onError: '#FFFFFF',
    container: '##FFDAD6',
    onContainer: '#690005'
  },
  success: {
    main: '#198754',
    onSuccess: '#FFFFFF',
    container: '#d1e7dd',
    onContainer: '#0f5132'
  },
  surface: {
    main: '#FFFFFF',
    dim: '#9F98B3',
    containerLowest: '#F2F0FA',
    containerLow: '#F0EEF6',
    container: '#E8E5F0',
    containerHeight: '#DDDAE7',
    containerHighest: '#D0CDDA',
    onSurface: '#1B1B1F',
    onSurfaceVariant: '#40404F'
  },
  background: '#F8F6FD',
  outline: '#73738C',
  outlineVariant: '#DDDAE7',
  black: '#17161E'
}

export type AppColors = typeof colorsLight
